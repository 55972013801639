.Main{
    max-width: 80%;
    display: grid;
    grid-gap: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    height: fit-content;

    grid-template: 
    ". menu" auto
    "Sidebar App" auto 
    "Footer Footer" auto/
    150px auto ;
}

.App{
grid-area: App;

box-shadow: 0 15px 45px -10px rgba(0,0,0,0.05);
padding: 15px;
border-radius: 10px;
min-height: 500px;


}

.Title{
    width: fit-content;    
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    font-weight: 700;
}