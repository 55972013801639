.HTTPCallableContainer{
    
    max-width: fit-content;
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.HTTPCallableButton{
    opacity: 1;
    
}